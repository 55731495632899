#contactPage{
    min-height: calc(60vh - 4rem);
    width: 100vw;
    max-width: 65rem;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contactPageTitle{
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    margin-top: 6rem;
}

.clientDesc{
    font-size: 1.1rem;
    font-weight: 400;
    max-width: 50rem;
    padding: 0 2rem;
    margin-bottom: 1rem;
}

.clientImgs{
    margin: 1rem 0;
    display: flex;
    flex-wrap: wrap;
}

.clientImg{
    object-fit: cover;
    width: 50%;
    max-width: 10rem;
    margin: auto;
    padding: 0.5rem;
    border:  rgb(163, 163, 163) 0.15rem solid;
    border-radius: 1rem;
}

.clientImg:hover{
    transform: scale(1.1);
    border-color: yellow;
}

.contactPageTitle{
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    margin-top: 6rem;
}

.contactDesc {
  font-size: 1.1rem;
  font-weight: 400;
  max-width: 50rem;
  margin: 0 auto; 
  padding: 0; 
  margin-bottom: 1rem;
}


.contactForm{
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 60rem;
}

.name, .email, .msg{
    font-size: medium;
    width: 100%;
    max-width: 45rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    color: #fff;
    border: none;
    border-radius: 0.5rem;
    background-color: rgb(40, 40, 40);
}

.submitBtn{
    background-color: #fff;
    border: none;
    border-radius: 2rem;
    padding: 0.75rem 3.5rem;
    margin: 2rem;
    font-weight: 400;
}

.submitBtn:hover{
    background-color: yellow;
}

.links{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.link{
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin: 0 0.75rem;
}

.link:hover{
    transform: scale(1.3);
}

.success-message {
    color: #4caf50; /* Green color for success */
    font-size: 1.2rem;
    font-weight: bold;
    margin: 1rem auto;
  }
  
  .error-message {
    color: #ff5722; /* Orange color for error */
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0.5rem;
  }


@media (max-width: 768px) {
    .clientImgs {
        flex-direction: column; 
    }
    
    .clientImg {
        margin-top: 0.5rem;
    }
}

  

  
