.footer {
    width: 100vw;
    height: 4rem;
    background-color: rgb(40, 40, 40);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white; 
    font-weight: 300;
    font-size: small;
    margin-top: 2rem;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-content p {
    margin: 0.2rem 0.5rem;
  }
  
  .footer-content a {
    color: yellow; 
    text-decoration: none;
  }
  
  .footer-content a:hover {
    text-decoration: underline;
  }
  