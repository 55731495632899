
#aboutMe{
    overflow: hidden;
    width: 100vw;
    max-width: 85rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.aboutMeTitle{
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    margin-top: 6rem;
}


.aboutMeDesc{
    font-size: 1.1rem;
    font-weight: 400;
    max-width: 50rem;
    padding: 0 2rem;
    margin-bottom: 1rem;
}

.about-containers {
    display: flex;
    justify-content: space-between; 
    gap: 2rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .details-container {
    width: 50%;
    box-sizing: border-box; 
    padding: 3rem;
    border-radius: 2rem;
    border:  rgb(163, 163, 163) 0.1rem solid;
    text-align: center;
  }


  @media screen and (max-width:720px) {

    .about-containers{
        display: grid;
    }

    .details-container{
        width: 100%;
    }
}


.experience-link {
    color: white; /* Example style, replace with desired color */
    text-decoration: underline;
  }