
  #skills{
    overflow: hidden;
    width: 100vw;
    max-width: 80rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.skillTitle{
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    margin-top: 6rem;
}

.skillDesc{
    font-size: 1.1rem;
    font-weight: 400;
    max-width: 50rem;
    padding: 0 2rem;
    margin-bottom: 1rem;
}

.skillBars{
    margin: 1.5rem;
    width: 100vw;
    max-width: 80%;
    text-align: left;
}

.skillBar{
    display: flex;
    margin: 1rem;
    padding: 1.5rem 2rem;
    border-radius: 0.5rem;
    background: rgb(50, 50, 50);
}

.skillBarImg{
    object-fit: cover;
    height: 4rem;
    width: 4rem;
    margin-right: 2rem;
}

.skillBarText>p{
    font-size: 0.9rem;
    font-weight: 400;
}


  
.icon {
  cursor: pointer;
  height: 3rem;
  margin: 0.5rem;
}
  

@media screen and (max-width:720px) {
    .skillBar{
        display: grid;
    }
}
