#intro {
    position: relative;
    height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 80rem;
    margin: 0 auto;
    overflow: hidden;
}


.bg {
    position: absolute;
    z-index: -1;
    top: -4rem;
    object-fit: cover;
    right: 0; 
    height: 100vh;
}

.introContent {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff; /* Set text color to white or adjust as needed */
}

.hello{
    font-size: 1.75rem;
    font-weight: 100;
}

.introName{
    color: yellow;
}

.introPara{
    font-size: medium;
    font-weight: 200;
    letter-spacing: 1px;
}

.btn{
    background-color: #fff;
    margin: 1rem 0;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 2rem;
    font-weight: 600;
}

.btn:hover{
    background-color: yellow;
}

.btnImg{
    object-fit: cover;
    margin: 0 0.25rem;
    height: 1rem;
}

@media screen and (max-width:840px) {
    /* .bg{
        right: vw;
    } */

    .introContent{
        font-size: 10vw;
    }

    .hello{
        font-size: 4.5vw;
    }
}

@media screen and (max-width:480px) {
    .bg{
        right: -10vw;
    }

}